import useTranslation from 'hooks/useTranslation';
import Typography from '@mui/material/Typography';

interface ErrorScreenProps {
    statusCode?: number,
    title?: string,

    [key: string]: any
}

const ErrorScreen = ({ statusCode = 403, title, ...props }: ErrorScreenProps = {}) => {
    const { t } = useTranslation();
    return (
        <>
            <Typography
                variant={'h1'}
                sx={{
                    textAlign: 'center',
                    fontSize: { xs: 58, md: 88 },
                    fontWeight: { xs: 500, md: 500 },
                    mb: 2,
                    mt: 4
                }}
            >
                {t('layout.error.network.beforeErrorCode')} {statusCode}
            </Typography>
            <Typography
                sx={{
                    textAlign: 'center',
                    fontSize: { xs: 18, md: 18 },
                    fontWeight: { xs: 300, md: 300 },
                    textTransform: 'uppercase',
                    lineHeight: 1.2
                }}
            >
                {title || t(`layout.error.network.${statusCode}`)}
            </Typography>
        </>
    );
};


export default ErrorScreen;
export {
    ErrorScreen
};


