import ErrorScreen from 'components/layout/ErrorScreen';
import Container from '@mui/material/Container';

const Page404 = () => {

    return (
        <Container maxWidth={'lg'}>
            <ErrorScreen statusCode={404}/>
        </Container>
    );
};

const getStaticProps = async () => {
    return {
        props: {
            layoutProps: {
                headerType: 'return',
                mobileFooter: true
            }
        }
    };
};

export default Page404;
export { getStaticProps };

